<template>
  <div class="pa-9 pa-md-6 pa-lg-9">
    <v-container fluid>
      <h3 class="page-title mb-7 font-weight-bold">パートナー会社一覧</h3>
      <v-row class="mb-6" align="center">
        <v-col cols="4">
          <v-text-field
            placeholder="キーワード検索"
            v-model="filter.search"
            @input="getDataFromApi"
            dense
            outlined
            hide-details
          >
            <v-btn
              slot="append"
              color="blue"
              small
              @click="getDataFromApi"
              class="white--text"
              elevation="0"
              height="23"
            >
              検索
            </v-btn>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            :items="selectDataTypes"
            v-model="filter.type"
            item-text="name"
            item-value="id"
            @change="getDataFromApi"
          ></v-select>
        </v-col>
        <v-col class="text-right">
          <v-btn
            height="30"
            class="btn-new"
            color="#CFD3FE"
            depressed
            min-width="100"
            to="/management/partner/create"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            パートナー会社を登録する
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="$route.query.status">
        <v-col>
          <v-alert dense text :type="$route.query.status">
            {{ $route.query.message }}
          </v-alert>
        </v-col>
      </v-row>
      <Table
        :headers="headers"
        :items="partners"
        :items-per-page="20"
        :total-records="partnerPagination.records_total"
        :loading="loading"
        @click:row="detail"
        :footer="false"
        :number-of-pages="partnerPagination.total_pages"
        :options.sync="options"
      >
        <template v-slot:item.profile_image="{ item }">
          <img class="avatar" :src="getUserPhoto(item)" />
        </template>
        <template v-slot:item.type="{ item }">
          {{ dataTypes[item.type] }}
        </template>
        <template v-slot:item.actions="{ item }" class="mt-2">
          <v-btn color="primary mr-2" fab x-small>
            <v-icon
              @click="$router.push('/management/partner/edit/' + item.id)"
              small
              >mdi-pencil</v-icon
            >
          </v-btn>
          <!--        <v-btn color="error" fab x-small>-->
          <!--          <v-icon small>mdi-delete</v-icon>-->
          <!--        </v-btn>-->
        </template>
      </Table>
    </v-container>
  </div>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
export default {
  name: 'List',
  components: { Table },
  data() {
    return {
      options: {},
      filter: {
        search: '',
        type: 3
      },
      loading: true,
      headers: [
        { text: 'タイプ', align: 'center', value: 'type' },
        { text: '', align: 'center', value: 'profile_image', sortable: false },
        { text: 'パートナー会社名', align: 'center', value: 'name' },
        {
          text: '担当者名',
          align: 'center',
          value: 'name_of_person_in_charge'
        },
        {
          text: '担当者名電話番号',
          align: 'center',
          value: 'mobile_number_of_person_in_charge'
        },
        { text: '', align: 'center', value: 'actions', sortable: false }
      ],
      dataTypes: [
        'マンション・ハウスメーカー',
        '発注会社',
        '元請会社',
        '協力会社',
        //'協力会社'
      ],
      root: process.env.VUE_APP_BASEURL,
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  computed: {
    partners() {
      return this.$store.getters.allPartners
    },
    partnerPagination() {
      return this.$store.getters.partnerPagination
    },
    selectDataTypes() {
      let types = []
      this.dataTypes.forEach((e, key) => {
        types.push({ id: key, name: e })
      })

      return types
    }
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      let params = {
        search: this.filter.search,
        type: this.filter.type,
        paginate: this.options.itemsPerPage,
        page: this.options.page
      }
      this.$store
        .dispatch('PARTNER_GET_ALL', params)
        .then(() => (this.loading = false))
    },
    getUserPhoto(user) {
      return user.profile_image
        ? user.profile_image_url
        : `${this.root}images/userblank-rectangle.png`
    },
    detail(item) {
      this.$router.push({ path: '/management/partner/edit/' + item.id })
    },
  }
}
</script>

<style src="./List.scss" lang="scss" scoped></style>
